
import { Component, InjectReactive, Mixins, Vue } from "vue-property-decorator";
import BoxTape from "@/components/app/AppTape.vue";
import GradientBtn from "@/components/base/GradientBtn.vue";
import Price from "@/components/base/Price.vue";
import { UserItemEntity } from "@/entities/user-item.entity";
import { USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import { BoxEntity } from "@/entities/box.entity";
import BorderContainer from "@/components/base/BorderContainer.vue";
import ItemList from "@/components/items/ItemList.vue";
import shirpImg from "../assets/shirp.png";
import gql from "graphql-tag";
import {
  AccountFragment,
  AccountShortFragment,
  BoxFragment,
  FragmentBoxFragment,
  FreeBoxFragment,
  GunBoxFragment,
  SnowBoxFragment,
  UserItemShortFragment,
  UserItemWithoutUserFragment,
} from "@/graphql/fragments";
import ScrollFetchMore from "@/components/base/ScrollFetchMore.vue";
import { FreeBoxEntity } from "@/entities/free-box.entity";
import TopAppBar from "@/components/base/TopAppBar.vue";
import { AccountBoxEntity } from "@/entities/account-box.entity";
import { AccountEntity } from "@/entities/account.entity";
import Account from "@/components/accounts/Account.vue";
import { Howl, Howler } from "howler";
import openBoxAudio from "@/assets/audio/openBox.mp3";
import { GunBoxEntity } from "@/entities/gun-box.entity";
import SnowPrice from "@/components/base/SnowPrice.vue";
import { useScroll } from "@/graphql/use-scroll";
import BonusPrice from "@/components/BonusPrice.vue";
import FragmentBoxesBalanceMixin from "@/mixins/fragment-boxes-balance.mixin";
import FragmentPrice from "@/components/base/FragmentPrice.vue";
import { useFindOne } from "@/graphql/use-find-one";
import { ESteamItemRarity } from "@/entities/steam-item.entity";

const OPEN_BOX_AUDIO = new Howl({
  src: [openBoxAudio],
  preload: true,
});

@Component({
  components: {
    FragmentPrice,
    BonusPrice,
    BoxTape,
    ScrollFetchMore,
    ItemList,
    BorderContainer,
    TopAppBar,
    Account,
    Price,
    SnowPrice,
    GradientBtn,
  },
  metaInfo() {
    return { title: this.pageTitle };
  },
  beforeRouteLeave(to, from, next) {
    OPEN_BOX_AUDIO.stop();
    next();
  },
  apollo: {
    box: {
      update(data) {
        const value = data[Object.keys(data)[0]];

        if (
          value &&
          !value.isAvailable &&
          !(
            value.__typename === "GunBox" ||
            value.__typename === "SnowBox" ||
            value.__typename === "FragmentBox"
          )
        ) {
          this.exit();
        }

        if (!value) {
          return value;
        }

        switch (this.boxType) {
          case "AccountBox":
            return new AccountBoxEntity(value);
          case "Box":
            return new BoxEntity(value);
          case "GunBox":
            return new GunBoxEntity(value);
          case "SnowBox":
            return value;
          case "FragmentBox":
            return value;
          case "FreeBox":
            return new FreeBoxEntity(value);
        }
      },
      query() {
        switch (this.boxType) {
          case "AccountBox":
            return gql`
              query ($boxId: ID!) {
                accountBox(_id: $boxId) {
                  _id
                  image
                  isAvailable
                  name
                  price
                  accountIds
                }
              }
            `;
          case "GunBox":
            return gql`
              query ($boxId: ID!) {
                gunBox(_id: $boxId) {
                  ...GunBox
                }
              }
              ${GunBoxFragment}
            `;

          case "SnowBox":
            return gql`
              query ($boxId: ID!) {
                snowBox(_id: $boxId) {
                  ...SnowBox
                }
              }
              ${SnowBoxFragment}
            `;

          case "FragmentBox":
            return gql`
              query ($boxId: ID!) {
                fragmentBox(_id: $boxId) {
                  ...FragmentBox
                }
              }
              ${FragmentBoxFragment}
            `;

          case "Box":
            return gql`
              query ($boxId: ID!) {
                box(_id: $boxId) {
                  ...Box
                }
              }
              ${BoxFragment}
            `;
          default:
            return gql`
              query ($boxId: ID!) {
                freeBox(_id: $boxId) {
                  ...FreeBox
                }
              }
              ${FreeBoxFragment}
            `;
        }
      },
      variables() {
        return {
          boxId: this.$route.params.id,
        };
      },
    },
    mostExpensiveItem: {
      query: useFindOne("UserItem", UserItemShortFragment),
      fetchPolicy: "no-cache",
      update(data) {
        return data.findOneUserItem
          ? new UserItemEntity(data.findOneUserItem)
          : null;
      },
      skip() {
        return (
          !this.box ||
          this.boxType !== "FragmentBox" ||
          this.box.fragmentId !== "FRAGMENT_4"
        );
      },
      variables() {
        return {
          options: {
            sort: "-price",
          },
          filter: {
            userId: { exists: false },
            state: { eq: "DEFAULT" },
            isNameFirstUnique: { eq: true },
          },
        };
      },
    },
    items: {
      update(data) {
        const value = data[Object.keys(data)[0]];

        if (
          (value && !value.docs.length) ||
          (this.boxType === "GunBox" &&
            value.docs.length < 25 &&
            this.box.price < value.docs[value.docs.length - 1].price)
        ) {
          this.exit();
        }

        if (!value) {
          this.itemsCount = null;
          return value;
        }

        this.itemsCount = value.totalDocs;

        switch (this.boxType) {
          case "AccountBox":
            return value.docs.map((item) => new AccountEntity(item));
          default:
            let items = value.docs.map((item) => new UserItemEntity(item));

            if (
              this.boxType === "GunBox" &&
              this.box.type === "KNIFE_OR_NOTHING"
            ) {
              items = items.filter(({ price }) => price > 50);
              items.push(
                new UserItemEntity({
                  _id: "nothing",
                  name: "Предмет | дешевле 50 Р",
                  image: shirpImg,
                  rarity: ESteamItemRarity.GREY,
                  price: 50,
                  __typename: "UserItem",
                })
              );
            }

            return items;
        }
      },
      skip() {
        return !this.box;
      },
      query() {
        if (this.boxType === "AccountBox") {
          return useScroll("Account", AccountShortFragment);
        }

        return useScroll("UserItem", UserItemShortFragment);
      },
      variables() {
        return {
          options: {
            sort: "-price",
            limit: 25,
          },
          filter: {
            ...(this.boxType === "AccountBox"
              ? {
                  _id: { in: this.box?.accountIds },
                  isForBox: { eq: true },
                }
              : this.boxType === "Box"
              ? {
                  price: {
                    gte: this.box?.itemsPriceRange[0],
                    lte: this.box?.itemsPriceRange[1],
                  },
                  isNameFirstUnique: { eq: true },
                  state: { eq: "DEFAULT" },
                }
              : this.boxType === "GunBox"
              ? {
                  price: {
                    gte: this.box.minItemPrice,
                    lte: this.box.maxItemPrice,
                  },
                  isNameFirstUnique: { eq: true },
                  state: { eq: "DEFAULT" },
                  gunBoxType: { eq: this.box?.type },
                  ...(this.box?.type === "CUSTOM"
                    ? { name: { in: this.box?.userItemNames } }
                    : {}),
                }
              : this.boxType === "SnowBox"
              ? {
                  price: {
                    gte: this.box.minItemPrice,
                    lte: this.box.maxItemPrice,
                  },
                  isNameFirstUnique: { eq: true },
                  state: { eq: "DEFAULT" },
                }
              : this.boxType === "FragmentBox"
              ? {
                  price: {
                    gte: this.box.minItemPrice,
                    lte: this.box.maxItemPrice,
                  },
                  isNameFirstUnique: { eq: true },
                  state: { eq: "DEFAULT" },
                }
              : { _id: { in: this.box?.itemIds }, state: { eq: "DEFAULT" } }),
          },
        };
      },
    },
  },
})
export default class BoxPage extends Mixins(FragmentBoxesBalanceMixin) {
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  private opening = false;
  private openingCount = 0;
  private loading = false;
  private itemsCount: number | null = null;
  private items: UserItemEntity[] | AccountEntity[] | null = null;
  private mostExpensiveItem: UserItemEntity | null = null;
  private box:
    | BoxEntity
    | FreeBoxEntity
    | AccountBoxEntity
    | GunBoxEntity
    | null = null;

  get boxType():
    | "Box"
    | "FreeBox"
    | "AccountBox"
    | "GunBox"
    | "SnowBox"
    | "FragmentBox" {
    if (this.$route.path.indexOf("gun-case") !== -1) {
      return "GunBox";
    }

    if (this.$route.path.indexOf("free-case") !== -1) {
      return "FreeBox";
    }

    if (this.$route.path.indexOf("account-box") !== -1) {
      return "AccountBox";
    }

    if (this.$route.path.indexOf("snow-case") !== -1) {
      return "SnowBox";
    }

    if (this.$route.path.indexOf("fragment-case") !== -1) {
      return "FragmentBox";
    }

    return "Box";
  }

  get pageTitle() {
    return this.box ? (this.box.name ? this.box.name : `Кейс`) : "Кейс";
  }

  get boxPriceWithBonusBalance() {
    return this.boxType === "GunBox" && this.user && this.box
      ? Number(
          (
            (this.box as GunBoxEntity).price -
            Math.min(
              (this.box as GunBoxEntity).price * 0.2,
              this.user.bonusBalance
            )
          ).toFixed(2)
        )
      : "price" in (this.box as any)
      ? (this.box as any).price
      : null;
  }

  get boxBonusPrice() {
    return this.box && this.boxPriceWithBonusBalance
      ? Number(
          (
            (this.box as GunBoxEntity).price -
            (this.boxPriceWithBonusBalance || 0)
          ).toFixed(2)
        )
      : 0;
  }

  exit(e?: Error) {
    let text = `Кейс временно недоступен`;

    if (this.boxType === "FreeBox" && e && this.box) {
      if (e.message.indexOf("wait until") !== -1) {
        const until = new Date(Number(/\s(\d+)$/.exec(e.message)![1]));
        text = `Кейс можно открывать 1 раз в ${this.$options.filters!.duration(
          (this.box as FreeBoxEntity).frequencyInMilliseconds,
          "humanize"
        )}, следующий кейс будет доступен ${this.$options.filters!.moment(
          until,
          "from",
          "now"
        )}`;
      } else if (e.message.indexOf("nickname should contain") !== -1) {
        const nicknameShouldContain = "buyskins.ru".toUpperCase();
        text = `Для открытия этого кейса Вы должны добавить <a class="white--text" href="https://steamcommunity.com/id/me/edit/info" target="_blank">в свой ник Steam</a> ${nicknameShouldContain}`;
      } else if (e.message.indexOf("you must make a deposit") !== -1) {
        text = `Для открытия этого кейса сумма пополнений баланса Вашего аккаунта за последние 3 дня должна быть не меньше чем ${
          (this.box as FreeBoxEntity).level
        } руб.`;
      }
    }

    this.$notify({
      text,
      type: "info",
    });

    this.$router.back();
  }

  async skip() {
    const tape = this.$refs.tape as any;
    return tape.skip();
  }

  async open() {
    this.opening = true;
    this.openingCount++;

    const tape = this.$refs.tape as any,
      mutation =
        this.boxType === "FreeBox"
          ? gql`
              mutation ($_id: ID!) {
                openFreeBox(_id: $_id) {
                  ...UserItemWithoutUser
                }
              }
              ${UserItemWithoutUserFragment}
            `
          : this.boxType === "AccountBox"
          ? gql`
              mutation ($_id: ID!) {
                openAccountBox(_id: $_id) {
                  ...Account
                }
              }
              ${AccountFragment}
            `
          : this.boxType === "GunBox"
          ? gql`
              mutation ($_id: ID!) {
                openGunBox(_id: $_id) {
                  ...UserItemWithoutUser
                }
              }
              ${UserItemWithoutUserFragment}
            `
          : this.boxType === "FragmentBox"
          ? gql`
              mutation ($_id: ID!) {
                openFragmentBox(_id: $_id) {
                  ...UserItemWithoutUser
                }
              }
              ${UserItemWithoutUserFragment}
            `
          : this.boxType === "SnowBox"
          ? gql`
              mutation ($_id: ID!) {
                openSnowBox(_id: $_id) {
                  ...UserItemWithoutUser
                }
              }
              ${UserItemWithoutUserFragment}
            `
          : gql`
              mutation ($_id: ID!) {
                openBox(_id: $_id) {
                  ...UserItemWithoutUser
                }
              }
              ${UserItemWithoutUserFragment}
            `;

    this.loading = true;
    const item: UserItemEntity | AccountEntity | null = await this.$apollo
      .mutate({
        mutation,
        variables: {
          _id: this.box?._id,
        },
      })
      .then(({ data }) => {
        const doc = data[Object.keys(data)[0]];
        return this.boxType === "AccountBox"
          ? new AccountEntity(doc)
          : new UserItemEntity(doc);
      })
      .catch((e) => {
        this.exit(e);
        return null;
      });

    this.loading = false;

    if (!item) {
      this.opening = false;
      return;
    }

    OPEN_BOX_AUDIO.play();
    await tape.animate(item);
    OPEN_BOX_AUDIO.stop();
    this.opening = false;

    if (item.__typename === "UserItem") {
      let text = `Поздравляем, вы выиграли предмет за ${item.price} руб. Он добавлен в Ваш инвентарь.`;

      if (item.fragmentId) {
        text += ` Также вы получили осколок для открытия весенних кейсов.`;
      }

      this.$notify({
        text,
        title: item.name,
        data: {
          class: `notification-${item.rarity}`,
          image: item.image,
        },
      });
    }
  }
}
